// **---------------------------------------------------**
// -------------------------------------------------------
// Google map BEGIN

// Google map END
// **---------------------------------------------------**
// -------------------------------------------------------

	//  -------------------------------------------------
	//  MAP - Using provided address, or coords 
 
	
	// Parameters
	var position_collection = new Array;
	var primary_position;
	var current_infoWindow;

	function set_center_map(map, fitbound){
		// If position_collection have more than one position and set to fitbound
		if (position_collection.length > 0 && fitbound)
		{
			var bound = new google.maps.LatLngBounds()
			// Loop on all position

		 	for (var i = 0; i < position_collection.length; i++) 
		 	{
		   		bound.extend(position_collection[i]);
			}
		  	
		  	map.fitBounds(bound);
		}
		else
		{	
			map.setCenter(primary_position);			
		}
	}

	function locate_using_coords(positionObject, map, markerBool, fitbound, multiple_markersBool){ 
	 	// If lat lng are provided
	 	lat_lng = { lat: positionObject.dataLat, lng: positionObject.dataLng};	
	 	// Recenter map using coords
	 	// set_center_map(map,lat_lng);
	 	// Place a marker using coords
	 	if (markerBool == 1 && ((!multiple_markersBool && positionObject.primary) || (multiple_markersBool && !positionObject.primary))) {
		    var marker = new google.maps.Marker({
		        map: map,
		        position: lat_lng,
		        icon: positionObject.img,
		        content: positionObject.html
		    });

	      	  // If html is not empty fo a info window

	      	  if (positionObject.html != "")
	      	  {	      	  	
				  var infowindow = new google.maps.InfoWindow({
					    content: positionObject.html
				  });
				  
				  marker.addListener('click', function() {
				    if (current_infoWindow) {
				    	current_infoWindow.close();
				    }
				    current_infoWindow = infowindow;
				    infowindow.open(resultsMap, marker);
				  });
	      	  }
		}

		// Set the collection of marker or the primary position

		if (positionObject.primary)
		{
			primary_position = lat_lng;
		}
		else
		{
	    	position_collection.push(lat_lng);
		}

	    set_center_map(map,fitbound);
	}

	function locate_using_address(positionObject,resultsMap,currentMapWrap, markerBool, fitbound, multiple_markersBool) {
		var geocoder = new google.maps.Geocoder();
	  	geocoder.geocode({'address': positionObject.address}, function(results, status) {
	    
	    // If geocoding successfull
	    if (status === google.maps.GeocoderStatus.OK) {
	      // lat_lng = results[0].geometry.location;

	      // Place a marker using address
	      if ((markerBool == 1) && ((!multiple_markersBool && positionObject.primary) || (multiple_markersBool && !positionObject.primary))) {

	      	 var marker = new google.maps.Marker({
		        map: resultsMap,
		        position: results[0].geometry.location,
		        icon: positionObject.img,
		      });      	

	      	  // If html is not empty fo a info window

	      	  if (positionObject.html != "")
	      	  {	      	  	
				  var infowindow = new google.maps.InfoWindow({
					    content: positionObject.html
				  });
				  
				  marker.addListener('click', function() {
				    if (current_infoWindow) {
				    	current_infoWindow.close();
				    }
				    current_infoWindow = infowindow;
				    infowindow.open(resultsMap, marker);
				  });
	      	  }
		   }

		   	 // Set the collection of marker or the primary position

	     	 if (positionObject.primary)
	     	 {
				primary_position = results[0].geometry.location;
	     	 }
	     	 else
	     	 {
	      	 	position_collection.push(results[0].geometry.location);
	     	 }

	      	 set_center_map(resultsMap,fitbound);
	    } // geocoding successful

	    // If geocoding unsuccessful from provided address 
	    // (ex: no address provided), Use provided lat-lng instead.
	    else { 
	      	console.log(currentMapWrap.attr("class")+" -> Geocoding unsuccessful ("+positionObject.address+"); " + status); 
	      	//currentMapWrap.hide();
	   		if(positionObject.dataLat != "" && positionObject.dataLng != "" && positionObject.dataLat != undefined && positionObject.dataLng != undefined){
		 		locate_using_coords(positionObject,resultsMap,markerBool,fitbound); 
		 	} 
		 	else{
		 		currentMapWrap.hide();
		 	}
	    } // If geocoding unsuccessful

	  }); 
	}

	function initMap(){
		// Witness pointer
		var index = 0; 
		var colors = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
];

		// If at least one map on page
		Maps = document.getElementsByClassName('Map');
		if (Maps.length > 0) { 
			for (var i = 0; i < Maps.length; i++) {
				m = Maps[i];

				// Parameters
				position_collection = new Array;
				primary_position;

		 		var zoom = null;
		 		if (m.dataset.zoom) {
		 			zoom = parseInt(m.dataset.zoom);
		 		}
		 		if (zoom == undefined || zoom == "") { zoom = 10; };

		 		var colored = m.dataset.colored; 
		 		if (colored == undefined || colored == "" || colored == 0) { colored = 0; } 
		 		else{ colored = colors;};

		 		var marker = m.dataset.marker; 
		 		if (marker == undefined || marker == "") { marker = 0; };

		 		var ui = m.dataset.ui; 
		 		if (ui == undefined || ui == "" || ui == 0) { ui = true; }
		 		else{ ui = false; }

		 		var fitbound = m.dataset.fitbound; 
		 		if (fitbound == undefined || fitbound == "" || fitbound == 0) { fitbound = false; }
		 		else{ fitbound = true; }

		 		// If address provided :  
		  		var address = m.dataset.addr; 
		  		var dataLat = "";
			 	var dataLng = "";
			 	var html = m.firstElementChild;
			 	var img = m.dataset.img;

			 	// Collection of marker html
			 	var html_marker_collection = new Array();

			 	// Bool for multiple markers
			 	var multiple_markers = 0;

			 	if (m.dataset.lat != "" && m.dataset.lng) {
			 		dataLat = m.dataset.lat;
			 		dataLng = m.dataset.lng;
			 	}

			 	//Generate position object with the primary position
			 	var primary_positionObject = {
			 		"primary":1,
			 		"address":address,
			 		"dataLat":dataLat,
			 		"dataLng":dataLng,
			 		"html":html,
			 		"img":img,
			 	};

		 		// If the map have multiple markers, setup the collection of marker
				var li_list = m.getElementsByTagName('li');

				if (li_list.length) {
					if (!fitbound) {
						html_marker_collection.push(primary_positionObject);
					}
					for (var j = 0; j < li_list.length; j++) {
						var li = li_list[j];

						html_marker_collection.push({
							"primary": 0,
							"address": li.dataset.addr,
							"dataLat": li.dataset.lat,
							"dataLng": li.dataset.lng,
							"html": li.innerHTML,
							"img": li.dataset.img
						});
					}
				}

		 		// Map declaration
		 		var map = new google.maps.Map(m, {
				    zoom: zoom,
				    center: {lat: 0, lng: 0},
				    scrollwheel: false, 
				    styles : colored,
				    disableDefaultUI: ui
				});

				// If is multiple markers
				if (html_marker_collection.length) {
					multiple_markers = true;

					// Loop on all markers

					for (var k = 0; k < html_marker_collection.length; k++) {
						// Initiate geocoding from map's data address, if provided
						if (html_marker_collection[k].address != "" && html_marker_collection[k].address != undefined) {
							locate_using_address(html_marker_collection[k], map, Map, marker, fitbound, multiple_markers);
						}
						// If not, use coordinates
						else if (html_marker_collection[k].dataLat != "" && html_marker_collection[k].dataLng != "" && html_marker_collection[k].dataLat != undefined && html_marker_collection[k].dataLng != undefined) {
							locate_using_coords(html_marker_collection[k], map, marker, fitbound, multiple_markers);
						} else {
							//console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
							Map.style.display = 'none';
						}
					}
				} else {
					// Initiate geocoding from map's data address, if provided
					if (address != "" && address != undefined) {
						locate_using_address(primary_positionObject, map, Map, marker, fitbound, multiple_markers);
					}
					// If not, use coordinates
					else if (dataLat != "" && dataLng != "" && dataLat != undefined && dataLng != undefined) {
						locate_using_coords(primary_positionObject, map, marker, fitbound, multiple_markers);
					} else {
						//console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
						Map.style.display = 'none';
					}
				}

				// set_center_map(map, fitbound); 
				index++;

				google.maps.event.addListenerOnce(map, 'tilesloaded', showGoogleMap);
			}
	 	} // If js-map contains something
 	}

 	function showGoogleMap() {
		// do some sutff (like fade in the map)
		Maps = document.getElementsByClassName('Map');
		for (var i = 0; i < Maps.length; i++) {
			Maps[i].style.opacity = '1';
		}
	}
