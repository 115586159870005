/* ---------------------------------------------------- */
// AJAX namespace

// ** default ** //
var pagedefault = Barba.BaseView.extend({
	namespace: 'pagedefault',

	onEnter: function() {
		// The new Container is ready and attached to the DOM.
	},
	onEnterCompleted: function() {
		// The Transition has just finished.

		// add class on the body to anim the content
		setTimeout(function() {
			theBody.classList.remove('page-transition');
			theBody.classList.add('page-show');
		}, 350);
	},
	onLeave: function() {
		// A new Transition toward a new page has just started.
		setTimeout(function() {
			theBody.classList.remove('page-show');
			theBody.classList.add('page-transition');
		}, 150);
	},
	onLeaveCompleted: function() {
		// The Container has just been removed from the DOM.
	}
});