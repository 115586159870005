// **---------------------------------------------------**
// -------------------------------------------------------
// DOMContentLoaded BEGIN
document.addEventListener('DOMContentLoaded', function(event) {
	// init namescapes	
	pagedefault.init();
	// init barba
	Barba.Pjax.start();
	Barba.Pjax.getTransition = function() {

		if (!theBody.classList.contains('ajax-transition-begin')) {
			theBody.classList.add('ajax-transition-begin')
		}

		if (PageDefault.valid()) {
			return PageDefault;
		}

		// fallback / default
		return PageDefault;
	};

	Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

	// Ignore barba on some links
	Barba.Pjax.preventCheck = function (event, element) {
		if (!Barba.Pjax.originalPreventCheck(event, element)) {
			return false;
		}

		// element that has .js-redirect class
		if (event.target.classList.contains('js-redirect') || event.target.parentNode.classList.contains('js-redirect')) {
			return false;
		}

		return true;
	};

	// The user click on a link elegible for AJAX.
	Barba.Dispatcher.on('linkClicked', function(element, event) {
		console.log('%c'+'---------------------'+'', 'color: #D84C4C;');
		console.log('%c'+'CLICKED'+'', 'color: #D84C4C;');

		toggleNav('close');

		/* if (event.target.classList.contains('js-redirect') || event.target.parentNode.classList.contains('js-redirect')) {
			Barba.Pjax.goTo(element);
		} */

		joss('');
	});

	// the new container has been loaded and injected in the wrapper
	Barba.Dispatcher.on('newPageReady', function(currentStatus, prevStatus, HTMLElementContainer, newPageRawHTML) {
		console.log('%c'+'---------------------'+'', 'color: #D84C4C;');
		console.log('%c'+'READY'+'', 'color: #D84C4C;');

		var link = currentStatus.url.split(window.location.origin)[1].substring(1); // get path of current page
		var navigations = document.querySelectorAll('nav');
		
		for (var i = 0; i < navigations.length; i++) {
			var navigation = navigations[i];
			var navigationLinks        = navigation.querySelectorAll('a');
			var navigationLinkIsActive = navigation.querySelectorAll("a[href='/" + link + "']");

			for (var j = navigationLinks.length - 1; j >= 0; j--) {
				navigationLinks[j].parentNode.classList.remove('active');
			}

			if(navigationLinkIsActive) {
				for (var j = navigationLinkIsActive.length - 1; j >= 0; j--) {
					navigationLinkIsActive[j].parentNode.classList.add('active');
				}
			}
			
		}

		Scrollbar.getAll()[0].setPosition(0, 0);
		ScrollItem.prototype.reset();
		ScrollItem.prototype.init();

		Accordion.prototype.clear();
		Accordion.prototype.init();

		initInputLabels();
		inputCheck();
		loadGoogleMap();
		initUploads();

		initLightBoxes();

		initAnchors();

		initForms();

		embedVideos();

		joss('');
	});

	// The transition has just finished and the
	// old Container has been removed from the DOM.
	Barba.Dispatcher.on('transitionCompleted', function() {
		console.log('%c'+'---------------------'+'', 'color: #D84C4C;');
		console.log('%c'+'COMPLETED'+'', 'color: #D84C4C;');

		// Scrollbar.getAll()[0].setPosition(0, 0);
		
		if (theBody.classList.contains('ajax-transition-begin')) {
			theBody.classList.add('ajax-transition-done');
			theBody.classList.remove('ajax-transition-begin');
		}

		ScrollItem.prototype.init();
		setTimeout(function() {
			ScrollItem.prototype.watch();
		}, firstLoadDelay);

		joss('')
	});
});
// DOMContentLoaded END
// -------------------------------------------------------
// **---------------------------------------------------**